// Japan
export const locale = {
  'password_require':'パスワードを入力してください。',
  'password_confirmation_require':'パスワードを入力してください。',
  'password_confirmation':'現行パスワードを重複で入力しないでください。',
  'email_require':'メールアドレスを入力してください',
  'email_format' : 'メールアドレスの形が不正です',
  'change_password_success':'パスワードを変更しました。',
    el: {
    colorpicker: {
      confirm: 'OK',
      clear: 'クリア'
    },
    datepicker: {
      now: '現在',
      today: '今日',
      cancel: 'キャンセル',
      clear: 'クリア',
      confirm: 'OK',
      selectDate: '日付を選択',
      selectTime: '時間を選択',
      startDate: '開始日',
      startTime: '開始時間',
      endDate: '終了日',
      endTime: '終了時間',
      prevYear: '前年',
      nextYear: '翌年',
      prevMonth: '前月',
      nextMonth: '翌月',
      year: '年',
      month1: '1月',
      month2: '2月',
      month3: '3月',
      month4: '4月',
      month5: '5月',
      month6: '6月',
      month7: '7月',
      month8: '8月',
      month9: '9月',
      month10: '10月',
      month11: '11月',
      month12: '12月',
      // week: '週次',
      weeks: {
        sun: '日',
        mon: '月',
        tue: '火',
        wed: '水',
        thu: '木',
        fri: '金',
        sat: '土'
      },
      months: {
        jan: '1月',
        feb: '2月',
        mar: '3月',
        apr: '4月',
        may: '5月',
        jun: '6月',
        jul: '7月',
        aug: '8月',
        sep: '9月',
        oct: '10月',
        nov: '11月',
        dec: '12月'
      }
    },
    select: {
      loading: 'ロード中',
      noMatch: 'データなし',
      noData: 'データなし',
      placeholder: '選択してください'
    },
    cascader: {
      noMatch: 'データなし',
      loading: 'ロード中',
      placeholder: '選択してください',
      noData: 'データなし'
    },
    pagination: {
      goto: '',
      pagesize: '',
      total: '総計 {total} 件',
      pageClassifier: ''
    },
    messagebox: {
      title: 'メッセージ',
      confirm: 'OK',
      cancel: 'キャンセル',
      error: '正しくない入力'
    },
    upload: {
      deleteTip: 'Delキーを押して削除する',
      delete: '削除する',
      preview: 'プレビュー',
      continue: '続行する'
    },
    table: {
      emptyText: ' 検索結果がありません。条件を変更して再度検索してください。',
      confirmFilter: '確認',
      resetFilter: '初期化',
      clearFilter: 'すべて',
      sumText: '合計'
    },
    tree: {
      emptyText: 'データなし'
    },
    transfer: {
      noMatch: 'データなし',
      noData: 'データなし',
      titles: ['リスト 1', 'リスト 2'],
      filterPlaceholder: 'キーワードを入力',
      noCheckedFormat: '総計 {total} 件',
      hasCheckedFormat: '{checked}/{total} を選択した'
    },
    image: {
      error: 'FAILED' // to be translated
    },
    pageHeader: {
      title: 'Back' // to be translated
    },
    popconfirm: {
      confirmButtonText: 'Yes', // to be translated
      cancelButtonText: 'No' // to be translated
    }
  }
  ,
  TRANSLATOR: {
    SELECT: "あなたが使う言語を選んでください"
  },
  MENU: {
    NEW: "新しい",
    ACTIONS: "行動",
    CREATE_POST: "新しい投稿を作成",
    PAGES: "Pages",
    FEATURES: "特徴",
    APPS: "アプリ",
    DASHBOARD: "ダッシュボード"
  },
  AUTH: {
    GENERAL: {
      OR: "または",
      SUBMIT_BUTTON: "提出する",
      NO_ACCOUNT: "アカウントを持っていない？",
      SIGNUP_BUTTON: "サインアップ",
      FORGOT_BUTTON: "パスワードをお忘れですか",
      BACK_BUTTON: "バック",
      PRIVACY: "プライバシー",
      LEGAL: "法的",
      CONTACT: "接触"
    },
    LOGIN: {
      TITLE: "Create Account",
      BUTTON: "Sign In"
    },
    FORGOT: {
      TITLE: "Forgot Password?",
      DESC: "Enter your email to reset your password",
      SUCCESS: "Your account has been successfully reset."
    },
    REGISTER: {
      TITLE: "Sign Up",
      DESC: "Enter your details to create your account",
      SUCCESS: "Your account has been successfuly registered."
    },
    INPUT: {
      EMAIL: "Email",
      FULLNAME: "Fullname",
      PASSWORD: "Password",
      CONFIRM_PASSWORD: "Confirm Password",
      USERNAME: "ユーザー名"
    },
    VALIDATION: {
      INVALID: "{{name}} is not valid",
      REQUIRED: "{{name}} is required",
      MIN_LENGTH: "{{name}} minimum length is {{min}}",
      AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
      NOT_FOUND: "The requested {{name}} is not found",
      INVALID_LOGIN: "The login detail is incorrect",
      REQUIRED_FIELD: "Required field",
      MIN_LENGTH_FIELD: "Minimum field length:",
      MAX_LENGTH_FIELD: "Maximum field length:",
      INVALID_FIELD: "Field is not valid"
    }
  },
  ECOMMERCE: {
    COMMON: {
      SELECTED_RECORDS_COUNT: "Selected records count: ",
      ALL: "All",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      FILTER: "Filter",
      BY_STATUS: "by Status",
      BY_TYPE: "by Type",
      BUSINESS: "Business",
      INDIVIDUAL: "Individual",
      SEARCH: "Search",
      IN_ALL_FIELDS: "in all fields"
    },
    ECOMMERCE: "eCommerce",
    CUSTOMERS: {
      CUSTOMERS: "Customers",
      CUSTOMERS_LIST: "Customers list",
      NEW_CUSTOMER: "New Customer",
      DELETE_CUSTOMER_SIMPLE: {
        TITLE: "Customer Delete",
        DESCRIPTION: "Are you sure to permanently delete this customer?",
        WAIT_DESCRIPTION: "Customer is deleting...",
        MESSAGE: "Customer has been deleted"
      },
      DELETE_CUSTOMER_MULTY: {
        TITLE: "Customers Delete",
        DESCRIPTION: "Are you sure to permanently delete selected customers?",
        WAIT_DESCRIPTION: "Customers are deleting...",
        MESSAGE: "Selected customers have been deleted"
      },
      UPDATE_STATUS: {
        TITLE: "Status has been updated for selected customers",
        MESSAGE: "Selected customers status have successfully been updated"
      },
      EDIT: {
        UPDATE_MESSAGE: "Customer has been updated",
        ADD_MESSAGE: "Customer has been created"
      }
    }
  },
  close_popup:'いいえ',
  close_popup_add_role:'いいえ',
  close_popup_error: '閉じる',
  search:'検索',
  export_csv:'CSV出力',
  item_setting:'項目設定',
  FORM:{
    VALIDATION:{
      REQUIRED: "{name} を入力してください",
      ISSET:"フォームに対して、ユニークキーとパスワードが１個づつ無いと登録不可。"
    },
    SETTING:{
      CHANGE_FORM_BASE:'フォームを変更する場合作成したフォームデータが全てクリアします。 本当にクリアしますか？',
      FORM_ITEM_CONFIRM_SUBMIT:'{form_name}の項目を登録しますか？',
      SUCCESS:'{form_name}成功しました。'
    }
  },
  export_zip:'ZIP出力',
  register_account: 'はい',
  RULE_FORM: {
    mail_required: "必須項目を入力してください。",
    mail_formatly: "メールアドレスが間違っています。",
    message_title : "アカウント：を登録しますか？",
  },
  USER_STATUS : {
    IN_USE: '利用中',
    UN_USED: '未利用',
  },
  ERROR_SERVER: '接続不安定ので、再度アクセスしてください。',
  REGISTER_SUCCESS: '成功しました。',
  NO: 'いいえ',
  YES: 'はい',
  OK: '閉じる',
  CONFIRM: '{answer_id}を承認しますか？',
  REJECT: '{answer_id}を否認しますか？',
  SAVE: '{answer_id}を保留しますか？',
  CHANGE_ROLE: '{answer_id}を担当者変更しますか？',
  UPDATE_VALUE: '{answer_id}を保存しますか？',
  UPDATE_SUCCESS: '更新の成功',
  DELETE_SUCCESS: '成功を削除',
  COPY_SUCCESS: 'コピーを成功しました。',
  CONFIRM_EDIT: '{form_name}を更新しますか？',
  CONFIRM_DELETE: '{form_name}を削除しますか？',
  DIALOG :{
    SUCCESS: '成功',
    ERROR: 'エラー',
    TILE_REFRESH_PAGE:'サイト再読み込み',
    BODY_REFRESH_PAGE:'ページを離れようとしています。入力したデータは保存されません。よろしいですか？',
    BTN_OK_REFRESH_PAGE:'再読み込み',
    BTN_CANCLE_REFRESH_PAGE:'キャンセル',
    TITLE_FORM_ITEM_DELETE:'確認',
    BODY_FORM_ITEM_DELETE:'削除します。よろしいですか？',
    BTN_OK_FORM_ITEM_DELETE:'はい',
    BTN_CANCLE_FORM_ITEM_DELETE:'いいえ',
    BODY_SAVE_DRAP:'途中保存されました。',
    CONFIRM_APPROVAL_CREATE:'承認プロセス名：{name}を登録しますか？',
    CONFIRM_APPROVAL_EDIT:'承認プロセス名：{name}を更新しますか？',
    TITLE_PERMISSON_DENIED: 'アカウントの権限がありませんですので、管理者にご連絡してください。',
    BTN_OKIE_PERMISSON_DENIED : "閉じる"
  },
  VIEW_VERSION_FILE: '版確認',
  Confirm_register: "アカウント：{mail} を登録しますか？",
  ROLE_STATUS : {
    IN_USE: '利用中、',
    UN_USED: '未利用',
  },
  TITLE_CONFIRM_ROLE: '権限名： {role_name}を登録しますか？',
  RESET_PASSWORD: 'ＰＷの再設定を行いますか？',
  APPROVAL_STEP_MESSAGHE:'{min}～{max} の間で入力してください。',
  CONFIRM_EDIT_ACCOUNT: 'アカウント：{email}を更新しますか？',
  CONFIRM_EDIT_ROLE: '権限名：{role_name}を変更しますか？',
  PERMISSION_DENIED: 'アクセス不可能です。再度確認してください。',
  HTTP_BAD_REQUEST: 'エラーが発生しました。',
  LOGOUT_SUCCESS: 'ログアウトしました。',
  MESSAGE_ERROR_APPROVAL: '<p>ステータスが変更できませんです。</p>' +
      '<p>権限は承認プロセス【{data}】を設定中です。<p>' +
      '承認プロセスを正しく修正してください。',
  MESSAGE_ERROR_APPROVAL_ADMIN: '<p>ステータスが変更できませんです。</p>' +
      '<p>承認プロセスはフォーム【{data}】を設定中です。<p>' +
      '承認プロセスを正しく修正してください。',
  LOGOUT_CANCELED:'ログアウトキャンセルしました。',
  TITLE_CONFIRM_TENANT: 'テナント：{tenant_name}を変更しますか？',
  FORM_NOT_SUPPORT_SPECIAL: 'このフォームには条件分岐・自動計算の項目の追加が不可能です。再度にご確認ください。',
};
