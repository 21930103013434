import { formAnswer } from "@/core/services/apis/formAnswer";
import { STATUS_CODE } from "@/core/config/constant";
import {SET_ERROR} from "@/core/services/store/auth.module";

const { AUTHENTICATE, ERROR_SERVER, SUCCESS } = STATUS_CODE;

export const GET_LIST_FORM_ANSWER = "getListFormAnswer";
export const FORM_ANSWER_LIST = "formAnswerList";
export const EXPORT_FORM_ANSWER_FILE_CSV = "exportFormAnswerFileCsv";

const state = {
    formAnswerList: {},
};

const getters = {
    formAnswerList:state => state.formAnswerList
};

const mutations = {
    [FORM_ANSWER_LIST](state, data) {
        state.formAnswerList = data;
    },
};

const actions = {
    async [GET_LIST_FORM_ANSWER](context,query){
        try {
            let response = await formAnswer.list(query);
            if (response.data.code == SUCCESS) {
                context.commit(FORM_ANSWER_LIST, response.data.data);
            } else {
                throw response.data.message;
            }
        } catch (e) {
            if (e.response.data.code == ERROR_SERVER) {
                context.commit(SET_ERROR, e.response.data.message);
                throw e.response.data.message
            } else {
                throw e;
            }
        }
    },
    async [EXPORT_FORM_ANSWER_FILE_CSV](context,query){
        try {
            let response = await formAnswer.getDataCsv(query);
            if (response.status == SUCCESS) {
                return response.data
            } else {
                throw response.data.message;
            }
        } catch (e) {
            if (e.response.data.code == ERROR_SERVER) {
                context.commit(SET_ERROR, e.response.data.message);
                throw e.response.data.message
            } else {
                throw e;
            }
        }
    }
};


export default {
    state,
    actions,
    mutations,
    getters
};
