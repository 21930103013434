import { roleBase } from "@/core/services/apis/roleBase";
import { STATUS_CODE } from "@/core/config/constant";
import {SET_ERROR} from "@/core/services/store/auth.module";
import * as constants from "@/core/config/constant";

const { AUTHENTICATE, ERROR_SERVER, SUCCESS } = STATUS_CODE;

export const CREATE_ROLE = "createRole";
export const GET_LIST_ROLE = "getListRole";
export const LIST_ROLE = "listRole";
export const ALL_ROLE = "allRole";
export const EXPORT_ROLE_FILE_CSV = "exportRoleFileCsv";
export const DETAIL_ROLE_EDIT = "listRoleEdit";
export const DETAIL_ROLE = "detailRole";
export const UPDATE_ROLE = "updateRole";
export const GET_ALL_ROLE = "getAllRole";

const state = {
    errors: [],
    listRole: {},
    listRoleEdit:{},
    allRole:[],
};

const getters = {
    listRole:state => state.listRole,
    listRoleEdit:state => state.listRoleEdit,
    allRole:state => state.allRole,
};

const mutations = {
    [LIST_ROLE](state, data) {
        state.listRole = data;
    },
    [DETAIL_ROLE_EDIT](state,data) {
        state.listRoleEdit= data
    },
    [ALL_ROLE](state, data) {
        state.allRole = data;
    },
};

const actions = {
    async [CREATE_ROLE](context, data) {
        try {
            let response = await roleBase.addRole(data);
                return response;
        } catch (e) {
            if (e.response.data.code == ERROR_SERVER) {
                context.commit(SET_ERROR, e.response.data.message);
                throw e.response.data.message
            } else {
                throw e;
            }
        }
    },
    async [GET_LIST_ROLE](context,query){
        try {
            let response = await roleBase.list(query);
            if (response.data.code == SUCCESS) {
                context.commit(LIST_ROLE, response.data.data);
            } else {
                throw response.data.message;
            }
        } catch (e) {
            if (e.response.data.code == ERROR_SERVER) {
                context.commit(SET_ERROR, e.response.data.message);
                throw e.response.data.message
            } else {
                throw e;
            }
        }
    },
    async [GET_ALL_ROLE](context,param){
        try {
            let response = await roleBase.roleOfTenant(param);
            if (response.data.code == SUCCESS) {
                context.commit(ALL_ROLE, response.data.data);
            } else {
                throw response.data.message;
            }
        } catch (e) {
            if (e.response.data.code == ERROR_SERVER) {
                context.commit(SET_ERROR, e.response.data.message);
                throw e.response.data.message
            } else {
                throw e;
            }
        }
    },
    async [EXPORT_ROLE_FILE_CSV](context,query){
        try {
            let response = await roleBase.getDataCsv(query);
            if (response.status == SUCCESS) {
                return response.data
            } else {
                throw response.data.message;
            }
        } catch (e) {
            if (e.response.data.code == ERROR_SERVER) {
                context.commit(SET_ERROR, e.response.data.message);
                throw e.response.data.message
            } else {
                throw e;
            }
        }
    },
    async [DETAIL_ROLE](context, id) {
        try {
          let response = await roleBase.detail(id);
          if (response.data.code == constants.STATUS_CODE.SUCCESS) {
              context.commit(DETAIL_ROLE_EDIT, response.data.data);
          } else {
              throw response.data.data.message;
          }
      } catch (e) {
          if (e.response.data.status == ERROR_SERVER) {
              context.commit(SET_ERROR, e.response.data.message);
              throw e.response.data.message
          } else {
              throw e;
          }
      }
      },
      async [UPDATE_ROLE](context, data) {
          try {
              let response = await roleBase.updateRole(data);
              return response;
          } catch (e) {
              if (e.response.data.status == ERROR_SERVER) {
                throw e.response.data.message
              } else {
                  throw e;
              }
          }
      }
};


export default {
    state,
    actions,
    mutations,
    getters
};
