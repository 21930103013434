const ID_TOKEN_KEY = "id_token";
import routeTenant, {getTenantCurrent} from "@/core/services/routeTenant.service";

export const getKeyToken = () => {
  let tenant = routeTenant.getTenantCurrent();
  if (tenant){
    return ID_TOKEN_KEY+'_'+tenant;
  }
  return ID_TOKEN_KEY;
};

export const getToken = () => {
  return window.localStorage.getItem(getKeyToken());
};

export const saveToken = token => {
  window.localStorage.setItem(getKeyToken(), token);
};

export const destroyToken = () => {
  window.localStorage.removeItem(getKeyToken());
};

export default { getToken, saveToken, destroyToken };
