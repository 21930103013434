export const BUILD_URL_DOWNLOAD_FILE = "buildUrlDownloadFile";
export const UPLOAD_FILE_USER = "uploadFileUser";
export const BYTES_TO_SIZE = "bytesToSize";
import { file } from "@/core/services/apis/file";
import { ENV } from "@/core/config/env";
const state = {

};

const getters = {

};

const mutations = {

};

const actions = {
    [BUILD_URL_DOWNLOAD_FILE](context,url){
        return ENV.APP_API_URL+'user/download/?url='+url;
    },
    async [UPLOAD_FILE_USER](context,data){
        const response = await file.uploadFile(data);
        return response.data;
    },
    async [BYTES_TO_SIZE](context,bytes){
        let i = 2;
        return await Math.round(bytes / Math.pow(1024, i), 2);
    },
}

export default {
    state,
    actions,
    mutations,
    getters
};
