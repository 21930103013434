import ApiService from "@/core/services/api.service";

export const formBase = {
    list: (data = null) => {
        const searchParams = new URLSearchParams();
        let params = data?data: {};
        Object.keys(params).forEach(key => searchParams.append(key, params[key]));
        return  ApiService.get("/form-base/list?"+searchParams.toString());
    },

    getDataCsv:(data = null) => {
        const searchParams = new URLSearchParams();
        let params = data?data: {};
        Object.keys(params).forEach(key => searchParams.append(key, params[key]));
        return  ApiService.get("/form-base/export-excel?"+searchParams.toString());
    },

    getFormBase:(param = null) => {
        return ApiService.get("/form-base/get-form-base/"+param);
    },

    handleEditForm:(data = null) => {
        return ApiService.post("/form-base/edit-form-base",data);
    },

    groupFormName: (data = null) => {
        return ApiService.get("/form-base/list-form-name");
    },

    delete: (id = null) => {
        return ApiService.post("/form-base/delete",{form_id:id});
    },
    cloneForm: (id = null) => {
        return ApiService.post(`/form-base/clone/${id}`);
    },
    getDataFormStructureCsv: ($id) => {
        return ApiService.get("/form-base/export-structure/"+$id);
    },
}
