
export const EXPERT_TYPE = {
    0: '種別選択',
    1: '個人',
    2: '法人'
}

export const EXPERT_TYPE_LIST = [
    {
        label: '個人',
        value: 1
    },
    {
        label: '法人',
        value: 2
    },
]

export const SOCIAL_TYPE = {
    1: 'Google',
    2: 'Apple',
    3: 'Facebook',
    4: 'Twitter',
    5: 'LINE',
    6: 'Mail',
}

export const STATUS_USER = {
    0: '強制停止',// STATUS_ADMIN_STOP
    1: 'アクティブ',// STATUS_ACTIVE
    2: '退会',// STATUS_SELF_DELETE
    3: '強制退会'// STATUS_ADMIN_DELETE
}

export const STATUS_USER_LIST = [
    {
        label: '強制停止', // STATUS_ADMIN_STOP
        value: 0
    },
    {
        label: 'アクティブ', // STATUS_ACTIVE
        value: 1
    },
    {
        label: '退会', // STATUS_SELF_DELETE
        value: 2
    },
    {
        label: '強制退会', // STATUS_ADMIN_DELETE
        value: 3
    }
]

export const TYPE_ORDER_UNPAID = [
    {
        label: '未払い',//unpaid
        value: 3
    },
    {
        label: '決済済み',//admin complete
        value: 4
    },
]

export const TYPE_ORDER = {
    0: 'ブロック',//rejected TODO
    1: 'アクティブ',//in order TODO
    2: '削除',//completed TODO
    3: '未払い ',//unfinished payment
    4: '決済済み',//admin complete
}

export const CALL_TYPE = {
    0: 'ボイス',//voice
    1: 'ビデオ',//video
}

export const LIMIT_PAGINATE = 20

export const STATUS_SERVICE_LIST = [
    {
        label: '出品中',
        value: 1
    },
    {
        label: '休止',
        value: 2
    },
    {
        label: '強制休止',
        value: 0
    },
]

export const STATUS_SERVICE = {
    0: '強制休止',//admin block
    1: '出品中',//active
    2: '休止'//expert stop
}

export const COUPON_TYPE = {
    0: 'A1',
    1: 'A2',
    2: 'A3',
    3: 'B',
    4: 'C',
}

export const COUPON_TYPE_LIST = [
    {
        label: 'A1',
        label_full: 'A-1 紹介（個別）',
        value: 0
    },
    {
        label: 'A2',
        label_full: 'A-2 紹介（累計）',
        value: 1
    },
    {
        label: 'A3',
        label_full: 'A-3 紹介（売上）',
        value: 2
    },
    {
        label: 'B',
        label_full: 'B  サービス購入金の割引',
        value: 3
    },
    {
        label: 'C',
        label_full: 'C 手数料割引',
        value: 4
    }
]

export const COUPON_STATUS = {
    0: '下書き',//Draft
    1: '公開',//can use
    2: '利用可',//active
    3: '期限切れ'//expired
}

export const EXPIRATION_TYPE = {
    1: '日間',
    2: '週間',
    3: 'ヶ月',
    4: 'ヶ月',
}

export const EXPIRATION_TYPE_LIST = [
    {
        label: '日間',
        value: 1
    },
    {
        label: '週間',
        value: 2
    },
    {
        label: 'ヶ月',
        value: 3
    },
    {
        label: '無限', // unlimited
        value: 4
    },
]

export const COUPON_STATUS_LIST = [
    {
        label: '下書き', // Draft
        value: 0
    },
    {
        label: '公開', // public
        value: 1
    },
    {
        label: '利用可', // active
        value: 2
    },
    {
        label: '期限切れ', // expired
        value: 3
    },
]


export const DISCOUNT_TYPE = {
    0: '円',//JPY
    1: '%',//percent
}

export const DISCOUNT_TYPE_LIST = [
    {
        label: '円',
        value: 0
    },
    {
        label: '%',
        value: 1
    },
]

export const EXPIRED_TYPE = {
    0: '', //expired after n days
    1: '', //expired same time expired_date
    2: 'Unlimited', //never expire
}

export const PREFECTURES = {
    1: '北海道',
    2: '青森県',
    3: '岩手県',
    4: '宮城県',
    5: '秋田県',
    6: '山形県',
    7: '福島県',
    8: '茨城県',
    9: '栃木県',
    10: '群馬県',
    11: '埼玉県',
    12: '千葉県',
    13: '東京都',
    14: '神奈川県',
    15: '新潟県',
    16: '富山県',
    17: '石川県',
    18: '福井県',
    19: '山梨県',
    20: '長野県',
    21: '岐阜県',
    22: '静岡県',
    23: '愛知県',
    24: '三重県',
    25: '滋賀県',
    26: '京都府',
    27: '大阪府',
    28: '兵庫県',
    29: '奈良県',
    30: '和歌山県',
    31: '鳥取県',
    32: '島根県',
    33: '岡山県',
    34: '広島県',
    35: '山口県',
    36: '徳島県',
    37: '香川県',
    38: '愛媛県',
    39: '高知県',
    40: '福岡県',
    41: '佐賀県',
    42: '長崎県',
    43: '熊本県',
    44: '大分県',
    45: '宮崎県',
    46: '鹿児島県',
    47: '沖縄県',
    99: '外国'
}

export const CATEGORY_NOTIFICATION_LIST = [
    {
        label: 'メンテナンス情報', // maintain information
        value: 1
    },
    {
        label: '障害情報', // bug information
        value: 2
    },
    {
        label: 'ニュース情報', // news
        value: 3
    },
    {
        label: 'キャンペーン情報', // campain information
        value: 4
    },
]

export const CATEGORY_NOTIFICATION = {
    1: 'メンテナンス情報', //maintain information
    2: '障害情報', //bug information
    3: 'ニュース情報', //news
    4: 'キャンペーン情報', //campain information
}

export const STATUS_NOTIFICATION_LIST = [
    {
        label: '下書き', // Draft
        value: 0
    },
    {
        label: '公開待ち', // wait sent
        value: 1
    },
    {
        label: '公開済み', // send
        value: 2
    },
]

export const STATUS_NOTIFICATION = {
    0: '下書き', // Draft
    1: '公開待ち', // wait sent
    2: '公開済み', // send
}

export const NDA_STATUS = {
    0: '確認前', // wait
    1: '確認済み', // success
}

export const GENDER = {
    0: '女性', // female
    1: '男性', // male
    2: 'その他', // other
}

export const ROLE_ADMIN = {
    3: '社内スタッフ',
    4: '受託先', // other
}

export const ROLE_ADMIN_LIST = [
    {
        label: '社内スタッフ',
        value: 3
    },
    {
        label: '受託先',
        value: 4
    },
]

export const TIME_APPEARL_LIST = {
    0: '3 日間',
    1: '1 週間',
    2: '2 週間',
    3: '4 週間',
}

export const STATUS_COUPON_USER = {
    0: '期限切れ',// block
    1: '利用可',// active
    2: '利用中',// using
    3: '利用街ち'// used
}

export const STATUS_COUPON_USER_LIST = {
    block: {
        value: 0,
        label: '期限切れ'
    },
    active: {
        value: 1,
        label: '利用可'
    },
    using: {
        value: 2,
        label: '利用中'
    },
    used: {
        value: 3,
        label: '利用街ち'
    }
}


export const INPUT_METHOD = [
    {
        label: 'テキスト（単行）',
        value: 1
    },
    {
        label: 'テキスト（複数行）',
        value: 2
    },
    {
        label: '選択（単一）',
        value: 3
    },
    {
        label: '選択（複数）',
        value: 4
    },
    {
        label: 'ファイル',
        value: 5
    },
    {
        label: 'ラベル',
        value: 6
    },
    {
        label: '日付',
        value: 7
    },
    {
        label: 'ファイル添付',
        value: 8
    },
    {
        label: '計算',
        value: 9
    },

]


export const CALC_METHOD = ['+','-','X',':','(',')'];
export const CALC_METHOD_FULL_SIZE = ['+','ー','×','÷','（','）'];

export const CALC_MAP = {
    '+':'+',
    '-':'ー',
    'X':'×',
    ':':'÷',
    '(':'（',
    ')':'）',
};

export const CHECK_METHOD = [
    {
        label: '必須',
        value: 1
    },
    {
        label: '重複',
        value: 2
    },
    {
        label: '数値',
        value: 3
    },
    {
        label: '半角英数字',
        value: 4
    },
    {
        label: 'カナのみ',
        value: 5
    },
    {
        label: 'URL',
        value: 6
    },
    {
        label: 'メールアドレス',
        value: 7
    },
    {
        label: '桁数',
        value: 8
    },
    {
        label: '容量',
        value: 9
    },
    {
        label: '拡張子',
        value: 10
    },
    {
        label: 'ひらがなのみ',
        value: 11
    },
    {
        label: '半角アルファベットのみ',
        value: 12
    },
    {
        label: '電話番号',
        value: 13
    },
]

export const SPECIAL_PROCESSING = [
    {
        label: 'ユニークキー（メールアドレス）',
        value: 1
    },
    {
        label: 'パスワード',
        value: 2
    },
    {
        label: '顧客側非表示（管理項目）',
        value: 3
    },
    {
        label: '電話番号（携帯電話）',
        value: 4
    }
]

export const SPECIAL_PROCESSING_EMPTY_COPY_ITEM = [1,2,4]

export const ITEM_ROLE = [
    {
        label: '申請者入力項目',
        value: 1
    },
    {
        label: '申請者・事務局入力項目',
        value: 2
    },
    {
        label: '事務局入力項目',
        value: 3
    }
]

export const ITEM_ROLE_SPECIAL_PROCESSING_EMPTY = [
    {
        label: '申請者入力項目',
        value: 1
    },
    {
        label: '申請者・事務局入力項目',
        value: 2
    }
]

export const ITEM_ROLE_KEY = {
    USER: 1,
    USER_ADMIN: 2,
    ADMIN: 3,
};

export const ITEM_ROLE_DEFAULT = 1;

export const SPECIAL_PROCESSING_CUSTOM = [
    {
        label: '顧客側非表示（管理項目）',
        value: 3
    }
]

export const CHECK_METHOD_UNIQUE = [3, 4, 5, 6, 7, 11, 12]

export const CHECK_METHOD_DEFAULT = [1, 2, 3, 4, 5, 6, 7, 8, 11, 12]

export const CHECK_METHOD_INPUT_TEXT = [1, 2, 3, 4, 5, 6, 7, 8, 11, 12,13]

export const CHECK_METHOD_DEFAULT_ALL = [1, 2, 3, 4, 5, 6, 7, 8,9,10]

export const CHECK_METHOD_INPUT_FILE = [1, 9, 10]

export const CHECK_METHOD_CUSTOM = [1, 2, 8,9, 10]

export const CHECK_METHOD_INPUT_CHECKBOK_ = [1]

export const DISPLAY_ORDER_DEFUALT = 1

export const STATUS_CODE = {
    AUTHENTICATE: 401,
    ERROR_SERVER: 500,
    BAD_REQUEST: 422,
    SUCCESS: 200,
    PERMISSION: 403,
    NOT_FOUND: 404,
    HTTP_BAD_REQUEST:400
}

export const USER_STATUS = [
    {
        label: '利用中',
        value: 1
    },
    {
        label: '未利用',
        value: 2
    },
]
export const ADMINUSER_STATUS = [
    {
        label: '利用中',
        value: 1
    },
    {
        label: '未利用',
        value: 2
    },
]

export const LIST_CHECK_RESET_FORM = ['item_group_name', 'label_name', 'input_method', 'special_processing']

export const FORM_ITEM_DEFAULT = ['range_from_digit', 'range_from_size', 'range_to_digit', 'range_to_size', 'special_processing', 'selections', 'acceptable_extensions', 'display_content','list_answer_show','answer_action']

export const STATUS_ANSWER= {
    APPROVAL: {
        KEY: 1,
        VALUE: '申請完了'
    },
    DENIAL: {
        KEY: 2,
        VALUE: '差戻し'
    },
    HOLD: {
        KEY: 3,
        VALUE: '確認中'
    },
    REVIEW: {
        KEY: 4,
        VALUE: '申請済み'
    }
  }

  export const STATUS_CONDITION = {
      DRAFT: {
          KEY: 2,
          VALUE: '一時保存',
      }
  }

  export const FORM_BASE_STATUS = [
      {
        label: '公開中',
        value: 1
    },
    {
        label: '非公開',
        value: 2
    },
  ]

export const FORM_ITEM_STATUS = {
    'save_drap': 1,
    'preview': 2,
}
  export const HANDLE_PROCESS = {
    CONFIRM: {
        KEY: 1,
        VALUE: '申請完了',
    },
    REJECT: {
        KEY: 2,
        VALUE: '差戻し',
    },
    SAVE: {
        KEY: 3,
        VALUE: '確認中',
    },
    CHANGE_OF_CHRAGE: {
        KEY: 4,
        VALUE: '担当変更',
    },
    SOFT_DELETE: {
        KEY: 5,
        VALUE: 'ゴミ箱へ',
    },
    FOCE_DELETE: {
        KEY: 6,
        VALUE: '完全削除',
    },
    RESTORE: {
        KEY: 7,
        VALUE: '元に戻す',
    },
    UPDATE_VALUE: {
        KEY: 8,
        VALUE: '更新',
    },
}
export const ROLE_STATUS = [
    {
        label: '利用中',
        value: 1
    },
    {
        label: '未利用',
        value: 2
    },
]
export const URL_FORM_ITEM = {
   'form_item':'/form/add-item',
   'form_item_tmp':'/form/save-drap-and-preview',
}

export const PASSWORD_LENGTH = {
    'min': 8,
    'max': 20,
}

export const CHECK_METHOD_LENGTH = 8

export const FIELDS = {
    TEXT: 1,
    TEXT_MULTIPLE_LINE: 2,
    SELECTION: 3,
    SELECTION_MULTIPLE: 4,
    FILE: 5,
    LABEL: 6,
    DATETIME: 7,
    FILE_DOWNLOAD: 8,
    CALCULATION: 9,
  }

  export const CHECK_TYPE_INPUT = {
    EMAIL: 1,
    PASSWORD: 2,
    HIDE_CUSTOMER: 3,
    PHONE:4
  }
export const SATUS_APPROVAL = [
    {
        label: '未使用',
        value: 0
    },
    {
        label: '使用中',
        value: 1
    },
]
export const MAX_STEP_APPROVAL = 9

export const T_APPROVAL_STATUS = {
        'approval' : {
            'key' : 1,
            'value' : '申請完了'
        },
        'denial' : {
            'key' : 2,
            'value' : '差戻し'
        },
        'hold' : {
            'key' : 3,
            'value' : '確認中'
        },
        'change_in_charge' : {
            'key' : 4,
            'value' : '担当変更'
        },
    }

export const FORM_BASE_DELETE_FLAG = {
    'ACTIVE': 0,
    'DELETE': 1,
}

export const STATUS_ANSWER_SEARCH= [
  {
        STATUS: '1',
        KEY: '申請完了'
    },
     {
         STATUS: '2',
         KEY: '差戻し'
    },
    {
        STATUS: '3',
        KEY: '確認中'
    },
    {
        STATUS: '4',
        KEY: '申請済み'
    },
    {
        STATUS: '5',
        KEY: '一時保存'
    }
]

export const SPECIAL_PROCESSING_KEY_UNIQUE_PASS= [1,2,4]
export const SPECIAL_PROCESSING_KEY_Phon= [4]

export const TENANT_STATUS = {
    1 : {
        label: '利用中',
        value: 1
    },
    2 : {
        label: '未利用',
        value: 2
    },
}

export const APPROVAL_KAISO = [
    {
        label: '自階層のみ',
        value: 0
    },
    {
        label: '上位',
        value: 1
    },
    {
        label: '最上位',
        value: 2
    },
]

export const QUANTITY_FORM_ITEM = 20

export const COPY_ITEM_STATUS = {
    'group': 1,
    'group_middle': 2,
}

export const SUPER_USER = 1;

export const CALCULATION_TYPE = {
    ITEM:1,
    CALCULATION:2,
    VALUE:3,
}

export const DISPLAY_START = {
    SHOW_TO_HIDE: 1,
    HIDE_TO_SHOW: 2,
}
export const ROUNDING_CALCULATION = [
    {
        label: '以下切捨て',
        value: 1
    },
    {
        label: '切り上げ',
        value: 2
    }
]

export const CALCULATION_ROUND = {
    DOWN:1,
    UP:2,
}
