<template>
    <div class=" wapper-pagination">
        <button :disabled="disabledFirstPage" type="button" @click="firstPage($event)" class="btn-next prev-arrow button-arrow-pagination">
            <i class="el-icon el-icon-arrow-left"></i>
        </button>
        <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="currentPagePaginate"
                background
                :page-sizes="listPageSize"
                :page-size="pageSizeDefault"
                layout="total, sizes, pager"
                :total="totalRecord"
                @next-click="nextClick">
        </el-pagination>
        <button :disabled="disabledLastPage" type="button" @click="lastPage($event)" class="btn-next button-arrow-pagination next-arrow">
            <i class="el-icon el-icon-arrow-right"></i>
        </button>
    </div>
</template>
<script>
    import * as constants from "@/core/config/constant";

    export default {
        props: {
            listPageSize: {
                type: Array,
                default: () => [10, 20, 50, 100]
            },
            pageSizeDefault: {
                type: Number,
                default: constants.LIMIT_PAGINATE
            },
            totalRecord: {
                type: Number,
                required: true,
            },
            currentPage: {
                type: Number,
                default: 1
            },
        },
        data() {
            return {
                currentPagePaginate: 1,
                constants: constants,
                changeSize: false
            }
        },
        watch: {
            currentPage() {
                this.currentPagePaginate = this.currentPage
            }
        },
        mounted(){
          this.currentPagePaginate = this.currentPage
        },
        computed:{
            disabledLastPage:function (){
               if (this.currentPage >= this.getLastPageNumber()){
                   return true;
               }else{
                   return false;
               }
            },
            disabledFirstPage:function (){
                if (this.currentPage == 1){
                    return true;
                }else{
                    return false;
                }
            }
        },
      methods: {
            handleSizeChange(val) {
                if (this.currentPagePaginate !== 1) {
                    this.changeSize = true
                }
                this.$emit('handleSizeChange', val)
            },
            handleCurrentChange(val) {
                // alert(1)
                // if (this.changeSize) {
                //     alert(2)
                //     this.changeSize = false
                //     return;
                // }
                this.$emit('handleCurrentChange', val)
            },
          nextClick(value){
              console.log(this.$route)
          },
          lastPage($e){
              let size = this.$route.query.size?this.$route.query.size:this.pageSizeDefault;
              let querySearch = this.$route.query;
              let querySize = {
                  size:size,
                  page:this.getLastPageNumber(),
              }
              this.$router.replace({
                  query:{...querySearch,...querySize},
              });
          },
          getLastPageNumber(){
              return Math.max(1, Math.ceil(this.totalRecord / this.pageSizeDefault));
          },
          firstPage(){
              let size = this.$route.query.size?this.$route.query.size:this.pageSizeDefault;
              let querySearch = this.$route.query;
              let querySize = {
                  size:size,
                  page:1,
              }
              this.$router.replace({
                  query:{...querySearch,...querySize},
              });
          }
        }
    }
</script>
