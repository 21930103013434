import { ENV } from "@/core/config/env";
import routeTenant from "@/core/services/routeTenant.service";
const baseUrlTenant = routeTenant.routeBaseConfig();
const form = [
    {
        path: baseUrlTenant ,
        name: "form-base",
        redirect: `${baseUrlTenant}/form/list`,
        component: () => import("@/view/layout/Layout"),
        children: [
            {
                name: "form-base-list",
                path: "form-base/list",
                component: () => import("@/view/pages/form/List")
            },  {
                path: "form-base/add",
                name: "form-base-add",
                component: () => import("@/view/pages/form/formBase/Index.vue")
            }, {
                path: "form-item/add",
                name: "form-item-add",
                component: () => import("@/view/pages/form/formItem/Loading.vue")
                // component: () => import("@/view/pages/form/formItem/Index.vue")
            }, {
                path: "form-base/edit/:form_id",
                name: "edit-form",
                component: () => import("@/view/pages/form/Edit.vue")
            }, {
                path: "preview/:form_base_url",
                name: "form-preview",
                component: () => import("@/view/pages/form/preView/Index.vue")
            },{
                path: "form-detail/:answer_id/:form_id",
                name: "form-base-detail",
                component: () => import("@/view/pages/answer_detail/Index.vue")
            },{
                path: "form-item/add-loading",
                name: "form-item-add-loading",
                component: () => import("@/view/pages/form/formItem/Loading.vue")
            }
        ]
    }
]

export default form
