import ApiService from "@/core/services/api.service";

export const approvalBase = {
    list: (data = null) => {
        const searchParams = new URLSearchParams();
        let params = data?data: {};
        Object.keys(params).forEach(key => searchParams.append(key, params[key]));
        return ApiService.get("/approval/list-approval?"+searchParams.toString());
    },

    getDataCsv:(data = null) => {
        const searchParams = new URLSearchParams();
        let params = data?data: {};
        Object.keys(params).forEach(key => searchParams.append(key, params[key]));
        return  ApiService.get("/approval/export-excel?"+searchParams.toString());
    },

    roleList: (data = null) => {
        const searchParams = new URLSearchParams();
        let params = data?data: {};
        Object.keys(params).forEach(key => searchParams.append(key, params[key]));
        return ApiService.get("/approval/list-role?"+searchParams.toString());
    },
}
