import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";
export const FORGOT_PASSWORD = 'forgot_password';
export const RESET_PASSWORD = 'reset_password';
// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";
export const CHECK_TOKEN="checkToken";

const state = {
  errors: null,
  user: {},
  currentTenant: null,
  callVerified: 0,
  isAuthenticated: !!JwtService.getToken()
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  setCallVerified(){
    return state.callVerified;
  }
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise(resolve => {
      ApiService.post("auth/login-verify", credentials)
        .then(({ data }) => {
          if(data.data.token){
            data.data.user.token = data.data.token;
            context.commit(SET_AUTH, data.data);
          }
          resolve(data);
        })
        .catch(response  => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [LOGOUT](context) {
    return new Promise(resolve => {
      ApiService.post("auth/logout")
        .then(({ data }) => {
          context.commit(PURGE_AUTH);
          resolve(data);
        });

        }).catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });

  },
  [REGISTER](context, credentials) {
    return new Promise(resolve => {
      ApiService.post("login", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return new Promise(resolve => {
        ApiService.get("auth/verify")
            .then(({data}) => {
              data.data.user.token = data.data.token;
              context.commit(SET_AUTH, data.data);
              resolve(data);

            })
            .catch(({response}) => {
              context.commit(SET_ERROR, response);
            });
      })
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_PASSWORD](context, payload) {
    const password = payload;

    return ApiService.put("password", password).then(({ data }) => {
      context.commit(SET_PASSWORD, data);
      return data;
    });
  },
  async [FORGOT_PASSWORD](context,payload){
    const data = payload
    return new Promise(resolve => {
      ApiService.post("auth/forgot-password", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            context.commit(SET_ERROR, response);
          });
    });

  },

  [RESET_PASSWORD](context,payload){
    const data = payload
    return new Promise(resolve => {
      ApiService.post("auth/reset-password", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            context.commit(SET_ERROR, response);
          });
    });

  },
  async [CHECK_TOKEN](context, data) {
    try {
      return await ApiService
          .post(`/auth/check-token`, {token:data});
    } catch (e) {
      if (e.response.data.status == ERROR_SERVER) {
        return  e.response.data.message
      } else {
        throw e;
      }
    }
  }


};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user.user;
    state.errors = {};
    JwtService.saveToken(state.user.token);
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};
