import { accountBase } from "@/core/services/apis/accountBase";
import { STATUS_CODE } from "@/core/config/constant";
import {SET_ERROR} from "@/core/services/store/auth.module";
import apiService from "@/core/services/api.service";
import * as constants from "@/core/config/constant";

export const DETAIL_ADMINUSER = "detail_adminuser";
export const DETAIL_ADMINUSER_NEW = "detail_adminuser_new";
export const UPDATE_ADMIN_USER = "updateAdminUser";


export const state = () => ({
    detailAdminUser:{}
})

export const getters = {
  detail_adminuser_new:state =>state.detailAdminUser

}

export const actions = {

    async [DETAIL_ADMINUSER](context, id) {
      try {
        let response = await accountBase.list(id);
        if (response.status == constants.STATUS_CODE.SUCCESS) {
            context.commit(DETAIL_ADMINUSER_NEW, response.data);

        } else {
            throw response.data.message;
        }
    } catch (e) {
        if (e.response.data.status == ERROR_SERVER) {
            context.commit(SET_ERROR, e.response.data.message);
            throw e.response.data.message
        } else {
            throw e;
        }
    }
    },
    async [UPDATE_ADMIN_USER](context, data) {
        try {
            let response = await accountBase.updateAdminUser(data);
            return response;
        } catch (e) {
            if (e.response.data.status == ERROR_SERVER) {
              throw e.response.data.message
            } else {
                throw e;
            }
        }
    }
  }
  export const mutations = {
    [DETAIL_ADMINUSER_NEW](state,data) {
      state.detailAdminUser= data
    },
  }

export default {
  state,
  actions,
  mutations,
  getters
}
