import { ENV } from "@/core/config/env";
import routeTenant from "@/core/services/routeTenant.service";
const baseUrlTenant = routeTenant.routeBaseConfig();
const approval = [
    {
        path: baseUrlTenant ,
        name: "form-base",
        component: () => import("@/view/layout/Layout"),
        children: [
            {
                name: "process-approval",
                path: "process-approval/create",
                component: () => import("@/view/pages/process_approval/Create")
            },{
                name: "process-approval-edit",
                path: "process-approval/edit/:processId",
                component: () => import("@/view/pages/process_approval/Edit")
            },
        ]
    }
]

export default approval
