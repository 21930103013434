import { ENV } from "@/core/config/env";
import routeTenant from "@/core/services/routeTenant.service";
const baseUrlTenant = routeTenant.routeBaseConfig();
const tenant = [
    {
        path: baseUrlTenant + "/tenant",
        name: "form-tenant",
        redirect: `${baseUrlTenant}/tenant/list`,
        component: () => import("@/view/layout/Layout"),
        children: [
            {
                name: "form-tenant-add",
                path: "add",
                component: () => import("@/view/pages/tenant/Form")
            },
            {
                name: "tenant-list",
                path: "list",
                component: () => import("@/view/pages/tenant/List")
            },
            {
                path: "edit/:tenant_id",
                name: "edit-tenant",
                component: () => import("@/view/pages/tenant/Form.vue")
            }
        ]
    }
]

export default tenant
