import ApiService from "@/core/services/api.service";

// action types
export const GET_ALL_LIST_FORM_BASE = "getAllListFormBase";
export const FORM_ITEM_REGISTER = "formItemRegister";
export const FORM_ITEM_SAVE_CHANGE_TAB = "formItemSaveChangeTab";
export const FORM_ITEM_DELETE_BY_ID = "formItemDeteleById";
export const COPY_GROUP = "copyGroup";
export const FORM_ITEM_UPLOAD_FILE = "formItemUploadFile";
export const GET_FORM_ITEM_BY_ID = "getFormItemById";
export const CREATE_FORM_BASE = "createFormBase";
export const GET_BY_PREVIEW = "getByPreview";

// mutation types

const state = {
    errors: [],
    formItem: [],
    formBase:[]
};

const getters = {

};

const actions = {
    [GET_ALL_LIST_FORM_BASE](context,credentials) {
        let status = credentials ? 1 : 0;
        return new Promise((resolve,reject) => {
            ApiService.get(`/form/get-all-from-base?status=${status}`)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(response  => {
                    reject(response)
                });
        });
    },
    [FORM_ITEM_REGISTER](context, credentials) {
        let url='';
        if (credentials.status){
            url=credentials.url+'/'+credentials.status
        }else {
            if (credentials.isSendmail){
                url=credentials.url + '/' + 1
            }else {
                url=credentials.url + '/' + 2
            }

        }
        return new Promise((resolve,reject) => {
            ApiService.post(url,credentials.data)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response)
                });
        });
    },
    [FORM_ITEM_SAVE_CHANGE_TAB](context, credentials) {
        return new Promise((resolve,reject) => {
            ApiService.post('/form/save-change-tab',credentials.data)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response)
                });
        });
    },
    [FORM_ITEM_DELETE_BY_ID](context, data) {
        return new Promise((resolve,reject) => {
            ApiService.post(`/form/delete-by-id/${data.id}/${data.form_id}`)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response)
                });
        });
    },
    [COPY_GROUP](context, data) {
        return new Promise((resolve,reject) => {
            ApiService.post(`/form/copy-group/${data.form}/${data.status}/${data.group}`)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response)
                });
        });
    },
    [FORM_ITEM_UPLOAD_FILE](context, credentials,id) {
        return new Promise((resolve,reject) => {
            ApiService.post(`/form/upload-file/${credentials.get('form_id')}`,credentials,{ 'Content-Type': 'multipart/form-data' })
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response)
                });
        });
    },
    [CREATE_FORM_BASE](context, credentials) {
        return new Promise((resolve,reject) => {
            ApiService.post(`/form/add-form-base`,credentials)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response)
                });
        });
    },
    [GET_FORM_ITEM_BY_ID](context, credentials) {
        return new Promise((resolve,reject) => {
            ApiService.get(`/form/get-form-base-by-id/${credentials.id}?tab=${credentials.tab}`)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response)
                });
        });
    },
    [GET_BY_PREVIEW](context, credentials) {
        return new Promise((resolve,reject) => {
            ApiService.get(`/form/get-form-item-by-id/${credentials.baseUrl}`)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response)
                });
        });
    }
};

const mutations = {
};

export default {
    state,
    actions,
    mutations,
    getters
};
