import ApiService from "@/core/services/api.service";

export const roleBase = {
    list: (data = null) => {
        const searchParams = new URLSearchParams();
        let params = data?data: {};
        Object.keys(params).forEach(key => searchParams.append(key, params[key]));
        return ApiService.get("/role/list-role?"+searchParams.toString());
    },

    getDataCsv:(data = null) => {
        const searchParams = new URLSearchParams();
        let params = data?data: {};
        Object.keys(params).forEach(key => searchParams.append(key, params[key]));
        return  ApiService.get("/role/export-excel?"+searchParams.toString());
    },

    detail: (id = null) => {
        return ApiService.get("role/detail-role/"+id);
    },

    updateRole: (data = null) => {
        return ApiService.post("/role/edit-role", data);
    },

    addRole: (data = null) => {
        return ApiService.post("/role/add-role", data);
    },

    allRole: () => {
        return ApiService.get("/role/all-role");
    },
    roleOfTenant: (param = null) => {
        return ApiService.get(`/role/role-of-tenant/${param.form_id ? param.form_id : ''}`);
    },
}
