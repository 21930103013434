import ApiService from "@/core/services/api.service";

export const accountBase = {
    list: (id = null) => {
        return  ApiService.get("user/detail-admin-user/"+id);
    },
    updateAdminUser: (data = null) => {
        return ApiService.post("/user/update-admin-user", data);
    }
}

export const list_account = {
    list: (data = null) => {
        const searchParams = new URLSearchParams();
        let params = data?data: {};
        Object.keys(params).forEach(key => searchParams.append(key, params[key]));
        return ApiService.get("/user/list-account?"+searchParams.toString());
    },
    getDataCsv:(data = null) => {
        const searchParams = new URLSearchParams();
        let params = data?data: {};
        Object.keys(params).forEach(key => searchParams.append(key, params[key]));
        return  ApiService.get("/user/export-excel?"+searchParams.toString());
    }
}
