import Vue from "vue";
import Router from "vue-router";
import form from "./form";
import {ENV} from "@/core/config/env";
import answer from "@/router/answer";
import approval from "@/router/approval";
import store from '../core/services/store/index'
import isSubscribed from '@/middleware/isSubscribed'
import checkTenantExits from '@/middleware/checkTenantExits'
import middlewarePipeline from './middlewarePipeline'
import templateEmail from "@/router/templateEmail";
import tenant from "@/router/tenant";
import routeTenant from "@/core/services/routeTenant.service";

Vue.use(Router);
const baseUrlTenant = routeTenant.routeBaseConfig();
console.log(baseUrlTenant);
const routeConfig = [
    {
        path: ENV.BASE_URL,
        component: () => import("@/view/pages/auth/login_pages/Login"),
    },
    {
        path: ENV.BASE_URL,
        component: () => import("@/view/pages/auth/LayoutAuth.vue"),
        children: [
            {
                name: "forgot-password",
                path: "forgot-password",
                component: () => import("@/view/pages/auth/ForgotPassword.vue")
            },
            {
                name: "forgot-password-tenant_dir",
                path: ":tenant_dir/forgot-password",
                component: () => import("@/view/pages/auth/ForgotPassword.vue"),
                meta: {
                    middleware: [
                        checkTenantExits
                    ]
                }
            },
            {
                name: "reset-password",
                path: "reset-password",
                component: () => import("@/view/pages/auth/ResetPassword.vue"),
                meta: {
                    middleware: [
                        isSubscribed
                    ]
                }
            }
        ]
    },
    {
        path: ENV.BASE_URL,
        component: () => import("@/view/pages/auth/login_pages/Login"),
        children: [
            {
                name: "login",
                path: "login",
                component: () => import("@/view/pages/auth/login_pages/Login")
            },
            {
                name: "register",
                path: "register",
                component: () => import("@/view/pages/auth/login_pages/Login")
            },
        ]
    },
    {
        path: ENV.BASE_URL,
        component: () => import("@/view/pages/auth/login_pages/Login"),
        children: [
            {
                name: "tenant_login",
                path: ":tenant_dir/login",
                component: () => import("@/view/pages/auth/login_pages/Login"),
                meta: {
                    middleware: [
                        checkTenantExits
                    ]
                }
            },
        ]
    },
    {
        path: ENV.BASE_URL,
        component: () => import("@/view/pages/auth/LayoutAuth.vue"),
        children: [
            {
                name: "tenant_reset_password",
                path: ":tenant_dir/reset-password",
                component: () => import("@/view/pages/auth/ResetPassword.vue"),
                meta: {
                    middleware: [
                        isSubscribed
                    ]
                }
            },
        ]
    },
]

const routeTenantConfig = [
    {
        // the 404 route, when none of the above matches
        path: baseUrlTenant + "/404",
        name: "404",
        component: () => import("@/view/pages/error/Error-1.vue")
    },
    {
        // the 404 route, when none of the above matches
        path: baseUrlTenant + "/403",
        name: "403",
        component: () => import("@/view/pages/error/Error-403")
    },
    {
        path: baseUrlTenant+"/account",
        name: "account",
        component: () =>
            import("@/view/layout/Layout"),
        children: [
            {
                path: "register",
                name: "register-account",
                component: () => import("@/view/pages/register_account/Index.vue")
            },
            {
                path: "list",
                name: "list-account",
                component: () => import("@/view/pages/list_account/Index.vue")

            },
            {
                path: "edit/:user_id",
                name: "edit-account",
                component: () => import("@/view/pages/adminUser/edit.vue")
            }
        ]
    },
    {
        path: baseUrlTenant+"/role",
        name: "role",
        component: () =>
            import("@/view/layout/Layout"),
        children: [
            {
                path: "add",
                name: "add-role",
                component: () => import("@/view/pages/role/Index.vue")
            },
            {
                name: "list-role",
                path: "list-role",
                component: () => import("@/view/pages/role/List.vue")
            },
            {
                path: "edit/:role_id",
                name: "edit-role",
                component: () => import("@/view/pages/role/Edit.vue")
            }
        ]
    },
    {
        path: baseUrlTenant+"/approval",
        name: "approval",
        component: () =>
            import("@/view/layout/Layout"),
        children: [
            {
                name: "list-approval",
                path: "list-approval",
                component: () => import("@/view/pages/list_approval/List.vue")
            },
        ]
    },
    {
        path: baseUrlTenant ,
        component: () => import("@/view/layout/Layout"),
        children: [
            {
                name: "permission-denied",
                path: "permission-denied",
                component: () => import("@/view/pages/permission_denied")
            }
        ]
    },
    ...form,
    ...answer,
    ...approval,
    ...templateEmail,
    ...tenant,
];

const router = new Router({
    mode: "history",
    routes: [
        ...routeConfig,
        ...routeTenantConfig,
    ]
});


router.beforeEach((to, from, next) => {
    if (!to.meta.middleware) {
        return next()
    }
    const middleware = to.meta.middleware

    const context = {
        to,
        from,
        next,
        store
    }

    return middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1)
    })
    return next()
})

export default router
