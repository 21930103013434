export const LIST_REDIRECT = "listRedirect";
export const DATA_LIST_REDIRECT = "dataListRedirect";
export const ADD_LIST_REDIRECT = "addListRedirect";


const state = {
    listRedirect: {
        form_item_redirect:false
    },
};

const getters = {
    dataListRedirect:state => state.listRedirect
};

const mutations = {
    [ADD_LIST_REDIRECT](state, data) {
        state.listRedirect['form_item_redirect'] = !!Number(data);
    },
};

const actions = {
    async [LIST_REDIRECT](context,query){
         context.commit(ADD_LIST_REDIRECT, query);
    },
};


export default {
    state,
    actions,
    mutations,
    getters
};
