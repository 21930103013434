import ApiService from "@/core/services/api.service";

export const formAnswer = {
    list: (data = null) => {
        const searchParams = new URLSearchParams();
        let params = data?data: {};
        Object.keys(params).forEach(key => searchParams.append(key, params[key]));
        return  ApiService.get("/form-answer/list?"+searchParams.toString());
    },
    getDataCsv:(data = null) => {
        const searchParams = new URLSearchParams();
        let params = data?data: {};
        Object.keys(params).forEach(key => searchParams.append(key, params[key]));
        return ApiService.get("/form-answer/export-excel?"+searchParams.toString());
    },

    handleStatus: (data = null) => {
        return ApiService.post("/form-answer/update-status-answer",data);
    },

    getDataAnswer: (data = null) => {
        return ApiService.get("/form-answer/detail/"+data['answer_id']+'/'+data['form_id']);
    },
    getListVersion: (data = null) => {
        return ApiService.post("/form-answer/get-list-version",data);
    },
    changeRole: (data = null) => {
        return ApiService.post("/form-answer/change-role",data);
    },
    softDetele: (data = null) => {
        return ApiService.post("/form-answer/soft-delete/"+data['answer_id']);
    },
    focetDetele: (data = null) => {
        return ApiService.post("/form-answer/foce-delete/"+data['answer_id']);
    },
    restore: (data = null) => {
        return ApiService.post("/form-answer/restore/"+data['answer_id']);
    },
    updateAnswerValue: (data = null) => {
        console.log(data);
        return ApiService.post("/form-answer/update-value",data);
    },
}
