import {ENV} from "@/core/config/env";

const groupPrefixRoute = [
    'form-base',
    'form-item',
    'form-answer',
    'account',
    'process-approval',
    'approval',
    'role',
    'tenant',
    'template',
    'form-detail',
    'preview',
    'login',
    'reset-password',
    'permission-denied'
];

export const checkTenantValid = (tenant) =>{
    return groupPrefixRoute.includes(tenant);
}

export const getTenant = () =>{
    let currentUrl = window.location.pathname;
    let urlBase = ENV.BASE_URL;
    let result = currentUrl.replace(urlBase+'/', "");
    let tenant = result.split("/")[1];
    if (urlBase.includes('admin')){
        tenant = result.substr(0, result.indexOf('/'));
    }
    return tenant;
}

export const getTenantCurrent = () => {
    let tenant = getTenant();
    if (!checkTenantValid(tenant)){
        return tenant;
    }
    return null;
}

export const routeBaseConfig = () =>{
    let tenant = getTenant();
    let urlBase = ENV.BASE_URL;
    urlBase = urlBase.trim();
    if (!checkTenantValid(tenant)){
        urlBase = ENV.BASE_URL+'/'+tenant
    }
    return urlBase;
}


export default {routeBaseConfig,checkTenantValid,getTenantCurrent};
