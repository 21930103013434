import { formBase } from "@/core/services/apis/formBase";
import { STATUS_CODE } from "@/core/config/constant";
import {SET_ERROR} from "@/core/services/store/auth.module";
import i18n from "@/core/plugins/vue-i18n";

const { AUTHENTICATE, ERROR_SERVER, SUCCESS } = STATUS_CODE;

export const GET_LIST_FORM_BASE = "getListFormBase";
export const FORM_BASE_LIST = "formBaseList";
export const EXPORT_FORM_BASE_FILE_CSV = "exportFormBaseFileCsv";
export const CLONE_FORM_BASE = "cloneFormBase";
export const GET_FORM_BASE = "getFormBase";
export const FORM_BASE = 'formBase';
export const HANDLE_EDIT_FORM = 'handleEditForm';
export const LIST_FORM_NAME = 'listFormName';
export const GET_LIST_FORM_NAME = "getListFormName";
export const DELETE_FORM_BASE_BY_ID = "deleteFormBaseById";
export const EXPORT_FORM_STRUCTURE = "exportFormStructure";

const state = {
    formBaseList: {},
    formBase: {},
    listFormName: {},
};

const getters = {
    formBaseList:state => state.formBaseList,
    formBase:state => state.formBase,
    listFormName:state => state.listFormName,
};

const mutations = {
    [FORM_BASE_LIST](state, data) {
        state.formBaseList = data;
    },
    [FORM_BASE](state,data){
        state.formBase = data;
    },
    [LIST_FORM_NAME](state,data){
        state.listFormName = data;
    }
};

const actions = {
    async [GET_LIST_FORM_BASE](context,query){
        try {
            let response = await formBase.list(query);
            if (response.data.code == SUCCESS) {
                context.commit(FORM_BASE_LIST, response.data.data);
            } else {
                throw response.data.message;
            }
        } catch (e) {
            if (e.response.data.code == ERROR_SERVER) {
                context.commit(SET_ERROR, e.response.data.message);
                throw e.response.data.message
            } else {
                throw e;
            }
        }
    },
    async [EXPORT_FORM_BASE_FILE_CSV](context,query){
        try {
            let response = await formBase.getDataCsv(query);
            if (response.status == SUCCESS) {
                return response.data
            } else {
                throw response.data.message;
            }
        } catch (e) {
            if (e.response.data.code == ERROR_SERVER) {
                context.commit(SET_ERROR, e.response.data.message);
                throw e.response.data.message
            } else {
                throw e;
            }
        }
    },
    async [CLONE_FORM_BASE](context,id){
        try {
            let response = await formBase.cloneForm(id);
            if (response.status == SUCCESS) {
                return response.data
            } else {
                throw response.data.message;
            }
        } catch (e) {
            if (e.response.data.code == ERROR_SERVER) {
                context.commit(SET_ERROR, e.response.data.message);
                throw e.response.data.message
            } else {
                throw e;
            }
        }
    },
    async [GET_FORM_BASE](context,form_id){
        try {
            let response = await formBase.getFormBase(form_id);
            if (response.data.code == SUCCESS) {
                let data = response.data.data;
                let listForm = data.listForm;
                let checkFormBase = true;
                for (let i = 0;i < listForm.length;i++){
                    let thisFormBase = listForm[i];
                    if (thisFormBase.form_id == data.formBase.parent_form_id){
                        checkFormBase = false;
                    }
                }
                if (checkFormBase && data.formBase.parent_form_id > 0){
                    data.formBase.parent_form_id = i18n.t("ROLE_STATUS.UN_USED");
                }
                context.commit(FORM_BASE,data);
            } else {
                throw response.data.message;
            }
        } catch (e) {
            if (e.response.data.code == ERROR_SERVER) {
                context.commit(SET_ERROR, e.response.data.message);
                throw e.response.data.message
            } else {
                throw e;
            }
        }
    },
    async [HANDLE_EDIT_FORM](context,data){
        try {
            let response = await formBase.handleEditForm(data);
            return response
        } catch (e) {
            if (e.response.data.code == ERROR_SERVER) {
                context.commit(SET_ERROR, e.response.data.message);
                throw e.response.data.message
            } else {
                throw e;
            }
        }
    },
    async [GET_LIST_FORM_NAME](context){
        try {
            let response = await formBase.groupFormName();
            if (response.data.code == SUCCESS) {
                context.commit(LIST_FORM_NAME, response.data.data);
            } else {
                throw response.data.message;
            }
        } catch (e) {
            if (e.response.data.code == ERROR_SERVER) {
                context.commit(SET_ERROR, e.response.data.message);
                throw e.response.data.message
            } else {
                throw e;
            }
        }
    },
    async [DELETE_FORM_BASE_BY_ID](context,id){
        try {
            let response = await formBase.delete(id);
            if (response.data.code == SUCCESS) {
                return response.data.data;
            } else {
                throw response.data.message;
            }
        } catch (e) {
            if (e.response.data.code == ERROR_SERVER) {
                context.commit(SET_ERROR, e.response.data.message);
                throw e.response.data.message
            } else {
                throw e;
            }
        }
    },
    async [EXPORT_FORM_STRUCTURE](context,id){
        try {
            let response = await formBase.getDataFormStructureCsv(id);
            console.log(response)
            if (response.status == SUCCESS) {
                return response.data
            } else {
                throw response.data.message;
            }
        } catch (e) {
            if (e.response.data.code == ERROR_SERVER) {
                context.commit(SET_ERROR, e.response.data.message);
                throw e.response.data.message
            } else {
                throw e;
            }
        }
    },
};


export default {
    state,
    actions,
    mutations,
    getters
};
