import { tenantApi } from "@/core/services/apis/tenant";
import { STATUS_CODE } from "@/core/config/constant";
import {SET_ERROR} from "@/core/services/store/auth.module";
import * as constants from "@/core/config/constant";

const { AUTHENTICATE, ERROR_SERVER, SUCCESS } = STATUS_CODE;

export const CREATE_TENANT = "createTenant";
export const GET_LIST_TENANT = "getListTenant";
export const LIST_TENANT = "listTenant";
export const GET_ALL_TENANT = "getAllTenant";
export const ALL_TENANT = "allTenant";
export const EXPORT_TENANT_FILE_CSV = "exportTenantFileCsv";
export const DETAIL_TENANT = "detailTenant";
export const UPDATE_TENANT = "updateTenant";
export const CHECK_TENANT_EXITS = "checkTenantExits";


const state = {
    listTenant: [],
    allTenant: [],
};

const getters = {
    listTenant:state => state.listTenant,
    allTenant:state => state.allTenant,
};

const mutations = {
    [LIST_TENANT](state, data) {
        state.listTenant = data;
    },
    [ALL_TENANT](state, data) {
        state.allTenant = data;
    }
};

const actions = {
    async [CREATE_TENANT](context, data) {
        try {
            return await tenantApi.addTenant(data);
        } catch (e) {
            if (e.response.data.code == ERROR_SERVER) {
                context.commit(SET_ERROR, e.response.data.message);
                throw e.response.data.message
            } else {
                throw e;
            }
        }
    },
    async [EXPORT_TENANT_FILE_CSV](context,query){
        try {
            let response = await tenantApi.getDataCsv(query);
            if (response.status == SUCCESS) {
                return response.data
            } else {
                throw response.data.message;
            }
        } catch (e) {
            if (e.response.data.code == ERROR_SERVER) {
                context.commit(SET_ERROR, e.response.data.message);
                throw e.response.data.message
            } else {
                throw e;
            }
        }
    },
    async [GET_LIST_TENANT](context,query){
        try {
            let response = await tenantApi.list(query);
            if (response.data.code == SUCCESS) {
                context.commit(LIST_TENANT, response.data.data);
            } else {
                throw response.data.message;
            }
            return response?.data?.data
        } catch (e) {
            if (e.response.data.code == ERROR_SERVER) {
                context.commit(SET_ERROR, e.response.data.message);
                throw e.response.data.message
            } else {
                throw e;
            }
        }
    },
    async [DETAIL_TENANT](context, id) {
        try {
            let response = await tenantApi.detail(id);
            return response?.data?.data;
        } catch (e) {
            if (e.response.data.status == ERROR_SERVER) {
                context.commit(SET_ERROR, e.response.data.message);
                throw e.response.data.message;
            } else {
                throw e;
            }
        }
    },
    async [UPDATE_TENANT](context, data) {
        try {
            return await tenantApi.updateTenant(data);
        } catch (e) {
            if (e.response.data.status == ERROR_SERVER) {
                return e.response.data.message
            } else {
                return e;
            }
        }
    },
    async [GET_ALL_TENANT](context,query){
        try {
            let response = await tenantApi.all(query);
            if (response.data.code == SUCCESS) {
                context.commit(ALL_TENANT, response.data.data);
            } else {
                throw response.data.message;
            }
            return response?.data?.data
        } catch (e) {
            if (e.response.data.code == ERROR_SERVER) {
                context.commit(SET_ERROR, e.response.data.message);
                throw e.response.data.message
            } else {
                throw e;
            }
        }
    },
    async [CHECK_TENANT_EXITS](context,tenantDir){
        try {
            return await tenantApi.checkTenantExits(tenantDir);
        } catch (e) {
            if (e.response.data.status == ERROR_SERVER) {
                return  e.response.data.message
            } else {
                throw e;
            }
        }
    },
};


export default {
    state,
    actions,
    mutations,
    getters
};
