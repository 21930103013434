import ApiService from "@/core/services/api.service";

// action types
export const GET_LIST_ROLE_APPROVAL = "getListRoleApproval";

export const GET_LIST_APPROVAL_ROLE = "getListApprovalRole";

export const REGISTER_NEW_APPROVAL = "registerNewApproval";

export const DETAIL_APPROVAL = "detailApproval";

// mutation types

const state = {
    errors: [],
    formItem: [],
    formBase:[]
};

const getters = {

};

const actions = {
    [GET_LIST_ROLE_APPROVAL](context) {
        return new Promise((resolve,reject) => {
            ApiService.get(`/approval/list-role`)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(response  => {
                    reject(response)
                });
        });
    },
    [GET_LIST_APPROVAL_ROLE](context) {
        return new Promise((resolve,reject) => {
            ApiService.get(`/approval/approval-form-base`)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(response  => {
                    reject(response)
                });
        });
    },
    [REGISTER_NEW_APPROVAL](context,credentials) {
        return new Promise((resolve,reject) => {
            ApiService.post(`/approval/update-or-create-approval`,credentials)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(response  => {
                    reject(response)
                });
        });
    },
    [DETAIL_APPROVAL](context,credentials) {
        return new Promise((resolve,reject) => {
            ApiService.get(`/approval/detail-process-approval/${credentials}`)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(response  => {
                    reject(response)
                });
        });
    },
};

const mutations = {
};

export default {
    state,
    actions,
    mutations,
    getters
};
