import Vue from "vue";
import App from "./App.vue";
import Element from 'element-ui'
import router from "./router/index";
import store from "@/core/services/store";
import enLocale from 'element-ui/lib/locale/lang/ja'
import ApiService from "@/core/services/api.service";
// import MockService from "@/core/mock/mock.service";
import {VERIFY_AUTH} from "@/core/services/store/auth.module";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";

Vue.config.productionTip = false;

//component
import Pagination from "@/view/components/Pagination";
import Dialog from "@/view/components/Dialog";

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "@/core/plugins/formvalidation";
import 'element-ui/lib/theme-chalk/index.css'
import "@/assets/css/style.css";
import "@/assets/css/custom.css";
import {baseMixin} from "./mixin";

Vue.use(Element, {
  i18n: (key, value) => i18n.t(key, value)
});
Vue.mixin(baseMixin);
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('my-pagination', Pagination)
Vue.component('my-dialog', Dialog)
// API service init
//ApiService.init();
// Vue.prototype.$api_url = "http://c2capi.local/"
// Remove this to disable mock API
// MockService.init();

router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load.

  Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);

  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");
