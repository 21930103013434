import { mapGetters } from "vuex";
import moment from "moment";
import Encoding from 'encoding-japanese';

export const baseMixin = {
    data: function () {
        return {
            message: 'hello',
            user: {
                name:"test name"
            }
        }
    },
    computed: {
        ...mapGetters([
            "isAuthenticated",
            "currentUser",
        ]),
    },
    methods: {
        serialize(obj) {
            let str = [];
            for (let p in obj)
                if (obj.hasOwnProperty(p)) {
                    str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                }
            return str.join("&");
        },
        // use with only status 422
        showErrorMessage(errors, formName = undefined) {
            this.formShowErrorMessage(errors, formName)
        },
        tableRowClassName({row, rowIndex}) {
            if (rowIndex%2 === 1) {
                return 'success-row';
            }
            return '';
        },
        convertTimeToUTC(time, format) {
            return time ? format ? moment(time).utc().format(format) : moment(time).utc().format('yyyy-MM-DD HH:mm:ss') : null
        },
        convertTimeToLocal(time, format) {
            return time ? format ? moment.utc(time).local().format(format) : moment.utc(time).local().format('yyyy-MM-DD HH:mm:ss') : null
        },
        formShowErrorMessage(errors, formName) {
            const fieldError = Object.keys(errors)
            this.$refs[formName].$children.forEach((children) => {
                if (children.prop === 'parent') {
                    if (fieldError.includes(children.prop)) {
                        children.validateState = 'error'
                        children.validateMessage = errors[children.prop][0]
                    }
                    children.$children.forEach((subChildren) => {
                        subChildren.$children.forEach((tinyChildren) => {
                            if (!fieldError.includes(tinyChildren.prop)) {
                                return
                            }
                            tinyChildren.validateState = 'error'
                            tinyChildren.validateMessage = errors[tinyChildren.prop][0]
                        })
                    })
                }
                if (!fieldError.includes(children.prop)) {
                    if (fieldError.includes('data')) {
                        this.notifyError(errors.data)
                    }
                    return
                }
                children.validateState = 'error'
                children.validateMessage = errors[children.prop][0]
            })
        },
        formatDateTime(datetime) {
            return datetime ? moment(datetime).format('yyyy年MM月DD日 HH:mm:ss') : ''
        },
        formatDate(date) {
            return date ? moment(date).format('yyyy年MM月DD日') : ''
        },
        formatMoney(money) {
            money = (typeof(parseInt(money)) == 'number')?parseInt(money):0;
            let val = (money/1).toFixed(0).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        formatMinute(number) {
            number = (typeof(parseInt(number)) == 'number')?parseInt(number):0;
            let val = (number/60).toFixed(0).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        turnOffErrorMessage(formName) {
            this.$refs[formName].$children.forEach((children) => {
                children.$children.forEach((subChildren) => {
                    if (subChildren.validateState) {
                        subChildren.validateState = ''
                        subChildren.validateMessage = ''
                    }
                    subChildren.$children.forEach((tinyChildren) => {
                        if (tinyChildren.validateState) {
                            tinyChildren.validateState = ''
                            tinyChildren.validateMessage = ''
                        }
                    })
                })
                if (children.validateState) {
                    children.validateState = ''
                    children.validateMessage = ''
                }
            })
        },
        checkErrorMessage(formName) {
            let check = false
            this.$refs[formName].$children.forEach((children) => {
                children.$children.forEach((subChildren) => {
                    if (subChildren.validateState) {
                        if (subChildren.validateMessage) {
                            check = true
                        }
                    }
                    subChildren.$children.forEach((tinyChildren) => {
                        if (tinyChildren.validateState) {
                            if (tinyChildren.validateMessage) {
                                check = true
                            }
                        }
                    })
                })
            })
            return check
        },
        turnOffErrorMessageField(formName, prop) {
            this.$refs[formName].$children.forEach((children) => {
                if (children.prop === 'parent') {
                    children.$children.forEach((subChildren) => {
                        subChildren.$children.forEach((tinyChildren) => {
                            if (tinyChildren.prop === prop) {
                                tinyChildren.validateState = ''
                                tinyChildren.validateMessage = ''
                            }
                        })
                    })
                }
                if (children.prop === prop) {
                    children.validateState = ''
                    children.validateMessage = ''
                }
            })
        },
        notifyError(message) {
            this.$notify.error({
                title: this.$t("DIALOG.ERROR"),
                message: message
            });
        },
        notifySuccess(message) {
            this.$notify({
                title: this.$t("DIALOG.SUCCESS"),
                message: message,
                type: 'success'
            });
        },
        convertToShiftJIS(target) {
            const uniArray = Encoding.stringToCode(target);
            const sjisArray = Encoding.convert(uniArray, {
                to: 'SJIS',
                from: 'UNICODE'
            });
            return new Uint8Array(sjisArray);
        },
        decodeShiftJIS(target){
            const codes = new Uint8Array(target);
            const encoding = Encoding.detect(codes);
            // Convert encoding to unicode
            return Encoding.convert(codes, {
                to: 'unicode',
                from: encoding,
                type: 'string'
            });
        },
        csvToArray(str, delimiter = ",") {
            const headers = str.slice(0, str.indexOf("\n")).split(delimiter);
            const rows = str.slice(str.indexOf("\n") + 1).split("\n");
            const arr = rows.map(function (row) {
                // var reg = /\|(?!(?:\w+\|?)+])/;
                let values = [];
                let x = row;
                 while (x.indexOf(',,') > 0){
                     x = x.replace(",,", ",####,");
                 }
                let myarr = x.match(/(".*?"|[^",]+)(?=\s*,|\s*$)/g);

                if (myarr){
                    for (var i = 0; i < myarr.length; i++){
                        if (myarr[i] === '####'){
                            values.push('');
                        }else{
                            values.push(myarr[i]);
                        }
                    }
                }
                const el = headers.reduce(function (object, header, index) {
                    object['field_'+index] = values[index]?values[index].replaceAll('"', '').trim():'';
                    return object;
                }, {});
                return el;
            });
            return arr;
        },
        exportCsvCommon(nameFile, data) {
            const convertCsvDate = this.convertToShiftJIS(data);
            let blob = new Blob([convertCsvDate], {
                type: "text/csv;charset=Shift_JIS",
            });
            let downloadLink = document.createElement("a");
            downloadLink.href = URL.createObjectURL(blob);
            downloadLink.download =
                nameFile  + moment().format('yyyyMMDDHHmmss', true) + ".csv";
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        },
        convertToSlug(str) {
            str = str.replace(/^\s+|\s+$/g, ''); // trim
            str = str.toLowerCase();
            // remove accents, swap ñ for n, etc
            var from = "àáäâèéëêễìíïîòóöôùúüûñç·/_,:;";
            var to   = "aaaaeeeeeiiiioooouuuunc------";
            for (var i=0, l=from.length ; i<l ; i++) {
                str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
            }
            str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
                .replace(/\s+/g, '-') // collapse whitespace and replace by -
                .replace(/-+/g, '-'); // collapse dashes
            return str;
        },
        onlyNumber(evt) {
            var charCode = (evt.which) ? evt.which : event.keyCode
            if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                return false;
            }
            return true;
        },
        exportZipCommon(nameFile, data) {
            let blob = new Blob([data], {
                type:'application/zip',
            });
            let downloadLink = document.createElement("a");
            downloadLink.href = URL.createObjectURL(blob);
            downloadLink.download =
                nameFile  + moment().format('yyyyMMDDHHmmss', true) + ".zip";
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        },
        enterByKeyCode(){
            window.location.reload();
        },
        hasPermissonCheck(check) {
            if (this.currentUser.role && this.currentUser.role[check] == 1){
                return true
            }
            return false
        },
        formItemCalculatorPage(totalItem,intItemAPage) {
            let totalCheck =  parseInt(totalItem) / intItemAPage;
            let totalCheckFloor = Math.floor(totalCheck);
            if (totalCheck < 1) {
                return 1
            }else if (totalCheck == totalCheckFloor) {
                return totalCheck
            }else {
                return totalCheckFloor + 1
            }
        },
        randomString(length){
            var result           = '';
            var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < length; i++ ) {
                result += characters.charAt(Math.floor(Math.random() *
                    charactersLength));
            }
            return result;
        },
    }
};
