import ApiService from "@/core/services/api.service";
import {template} from "@/core/services/apis/template";
import {STATUS_CODE} from "@/core/config/constant";
import {formBase} from "@/core/services/apis/formBase";
import i18n from "@/core/plugins/vue-i18n";
import {SET_ERROR} from "@/core/services/store/auth.module";
import {FORM_BASE, GET_FORM_BASE} from "@/core/services/store/formBase.module";
const { AUTHENTICATE, ERROR_SERVER, SUCCESS } = STATUS_CODE;
export const LIST_TEMPLATE = "listTemplate";
export const GET_DETAIL = "getDetail";
export const UPDATE_DETAIL = "updateDetail";
export const LIST = "list";
export const DETAIL = "detail";
const state = {
    list:{},
    detail:{},
};

const getters = {
    list:state => state.list,
    detail:state => state.detail,
};

const mutations = {
    [LIST](state, data) {
        state.list = data;
    },
    [DETAIL](state, data) {
        state.detail = data;
    },
};

const actions = {
    async [LIST_TEMPLATE](context){
        try {
            let response = await template.list();
            if (response.data.code == SUCCESS) {
                context.commit(LIST, response.data.data);
            } else {
                throw response.data.message;
            }
        } catch (e) {
            if (e.response.data.code == ERROR_SERVER) {
                context.commit(SET_ERROR, e.response.data.message);
                throw e.response.data.message
            } else {
                throw e;
            }
        }
    },

    async [GET_DETAIL](context,id){
        try {
            let response = await template.detail(id);
            if (response.data.code == SUCCESS) {
                context.commit(DETAIL,response.data.data);
            } else {
                throw response.data.message;
            }
        } catch (e) {
            if (e.response.data.code == ERROR_SERVER) {
                context.commit(SET_ERROR, e.response.data.message);
                throw e.response.data.message
            } else {
                throw e;
            }
        }
    },

    async [UPDATE_DETAIL](context,data){
        try {
            let response = await template.update(data);
            return response.data;
        } catch (e) {
            if (e.response.data.code == ERROR_SERVER) {
                context.commit(SET_ERROR, e.response.data.message);
                throw e.response.data.message
            } else {
                throw e;
            }
        }
    },



};

export default {
    state,
    actions,
    mutations,
    getters
};
