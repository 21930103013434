import { formAnswer } from "@/core/services/apis/formAnswer";
import { STATUS_CODE } from "@/core/config/constant";
import {SET_ERROR} from "@/core/services/store/auth.module";

const { AUTHENTICATE, ERROR_SERVER, SUCCESS,BAD_REQUEST } = STATUS_CODE;

export const GET_ANSWER_DETAIL = "getAnswerDetail";
export const ANSWER_DETAIL = "answerDetail";
export const ANSWER_HANDLE_STATUS = "answerHandleStatus";
export const GET_LIST_VERSION = "getListVersion";
export const TOGGLE_STATUS = "toggleStatus";
export const CHANGE_ROLE = "changeRole";
export const STORE_SOFT_DELETE = "storeSoftDetele";
export const STORE_FOCE_DELETE = "storeFoceDetele";
export const ERROR_MESSAGE_CHANGE_ROLE = "errorMessageChangeRole";
export const TOGGLE_TABLE_STEP = "toggleTableStep";
export const CHANGE_TOGGLE_TABLE_STEP = "changeToggleTableStep";
export const REMOVE_ERROR_CHANGE_ROLE = "removeErrorChangeRole";
export const RESTORE = "restore";
export const UPDATE_ANSWER_VALUE = "updateAnswerValue";

const state = {
    answerDetail : {},
    toggleStatus:{},
    errorMessageChangeRole:{},
    toggleTableStep:1,
};

const getters = {
    answerDetail:state => state.answerDetail,
    toggleStatus(state) {
        return state.toggleStatus;
    },
    errorMessageChangeRole:state => state.errorMessageChangeRole,
    toggleTableStep:state => state.toggleTableStep,
};

const mutations = {
   [ANSWER_DETAIL](state , data){
    state.answerDetail=data;
   },
   [TOGGLE_STATUS](state, status){
        state.toggleStatus = status;
   },
   [ERROR_MESSAGE_CHANGE_ROLE](state, status){
        state.errorMessageChangeRole = status;
   },
   [TOGGLE_TABLE_STEP](state, status){
        state.toggleTableStep = status;
   },
};

const actions = {
    async [GET_ANSWER_DETAIL](context,data){
        try {
            context.commit(ERROR_MESSAGE_CHANGE_ROLE,{});
            let response = await formAnswer.getDataAnswer(data);
            let statusObject = {}
            if (response.data.code == SUCCESS) {
                context.commit(ANSWER_DETAIL, response.data.data);
                for (var prop in response.data.data.form) {
                    statusObject[prop] = true;
                }
                context.commit(TOGGLE_STATUS,statusObject);
            } else {
                return response;
            }
        } catch (e) {
            if (e.response.data.code == ERROR_SERVER) {
                context.commit(SET_ERROR, e.response.data.message);
                throw e.response.data.message
            } else {
                throw e;
            }
        }
    },
    async [ANSWER_HANDLE_STATUS](context , data){
        try{
            let response = await formAnswer.handleStatus(data);
                return response
        }catch (e) {
            if (e.response.data.code == ERROR_SERVER) {
                context.commit(SET_ERROR, e.response.data.message);
                throw e.response.data.message
            } else {
                throw e;
            }
        }

    },
    async [GET_LIST_VERSION](context, data){
        try{
            let response = await formAnswer.getListVersion(data);
                return response
        }catch (e) {
            if (e.response.data.code == ERROR_SERVER) {
                context.commit(SET_ERROR, e.response.data.message);
                throw e.response.data.message
            } else {
                throw e;
            }
        }
    },
    async [CHANGE_ROLE](context, data){
        try{
            context.commit(ERROR_MESSAGE_CHANGE_ROLE,{});
            let response = await formAnswer.changeRole(data);
            if (response.data.code === BAD_REQUEST){
                context.commit(ERROR_MESSAGE_CHANGE_ROLE,response.data.data);
            }
            return response
        }catch (e) {
            if (e.response.data.code == ERROR_SERVER) {
                context.commit(SET_ERROR, e.response.data.message);
                throw e.response.data.message
            } else {
                throw e;
            }
        }
    },

    async [STORE_SOFT_DELETE](context, data){
        try{
            let response = await formAnswer.softDetele(data);
            return response
        }catch (e) {
            throw e;
        }
    },

    async [STORE_FOCE_DELETE](context, data){
        try{
            let response = await formAnswer.focetDetele(data);
            return response
        }catch (e) {
            throw e;
        }
    },

    async [RESTORE](context, data){
        try{
            let response = await formAnswer.restore(data);
            return response
        }catch (e) {
            throw e;
        }
    },

    async [CHANGE_TOGGLE_TABLE_STEP](context, data){
        if (state.toggleTableStep == 1){
            context.commit(TOGGLE_TABLE_STEP,0);
        }else{
            context.commit(TOGGLE_TABLE_STEP,1);
        }
    },

    [REMOVE_ERROR_CHANGE_ROLE](context, data){
        context.commit(ERROR_MESSAGE_CHANGE_ROLE,{});
    },

    async [UPDATE_ANSWER_VALUE](context,data){
        try{
            let response = await formAnswer.updateAnswerValue(data);
            return response;
        }catch (e) {
            throw e;
        }
    }

};


export default {
    state,
    actions,
    mutations,
    getters
};
