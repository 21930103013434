import ApiService from "@/core/services/api.service";

export const template = {
    list: () => {
        return  ApiService.get("/template-email/all");
    },
    detail: (id) => {
        return  ApiService.get("/template-email/detail/"+id);
    },
    update: (data) => {
        return ApiService.post("/template-email/update", data);
    }
}

