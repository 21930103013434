import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import profile from "./profile.module";
import formStore from "./formStore"
import formBase from "./formBase.module";
import answerDetail from "./answerDetail.module";
import account from "./account.module";
import formAnswer from "./formAnswer.module";
import confirmRedirectPage from "./confirmRedirectPage.module";
import adminuser from "./adminuser.module";
import file from "./file.module";
import approvalRole from "./approvalRole.module";
import role from "./role.module";
import approval from "./approval.module";
import template from "./template.modal";
import tenant from "./mtenant.module";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    profile,
    formStore,
    formBase,
    adminuser,
    answerDetail,
    account,
    formAnswer,
    confirmRedirectPage,
    role,
    approval,
    approvalRole,
    file,
    template,
    tenant,
  }
});
