import { list_account } from "@/core/services/apis/accountBase";
import { STATUS_CODE } from "@/core/config/constant";
import {SET_ERROR} from "@/core/services/store/auth.module";

const { AUTHENTICATE, ERROR_SERVER, SUCCESS } = STATUS_CODE;

export const GET_LIST_ACCOUNT = "getListAccount";
export const LIST_ACCOUNT = "listAccount";
export const EXPORT_LIST_ACCOUNT_CSV = "exportListAccountFileCsv"

const state = {
    listAccount: {},
};

const getters = {
    listAccount:state => state.listAccount
};

const mutations = {
    [LIST_ACCOUNT](state, data) {
        state.listAccount = data;
    },
};

const actions = {
    async [GET_LIST_ACCOUNT](context,query){
        try {
            let response = await list_account.list(query);
            if (response.data.code == SUCCESS) {
                context.commit(LIST_ACCOUNT, response.data.data);
            } else {
                throw response.data.message;
            }
        } catch (e) {
            if (e.response.data.code == ERROR_SERVER) {
                context.commit(SET_ERROR, e.response.data.message);
                throw e.response.data.message
            } else {
                throw e;
            }
        }
    },
    async [EXPORT_LIST_ACCOUNT_CSV](context,query){
        try {
            let response = await list_account.getDataCsv(query);
            if (response.status == SUCCESS) {
                return response.data
            } else {
                throw response.data.message;
            }
        } catch (e) {
            if (e.response.data.code == ERROR_SERVER) {
                context.commit(SET_ERROR, e.response.data.message);
                throw e.response.data.message
            } else {
                throw e;
            }
        }
    }
};


export default {
    state,
    actions,
    mutations,
    getters
};
