import { ENV } from "@/core/config/env";
import routeTenant from "@/core/services/routeTenant.service";
const baseUrlTenant = routeTenant.routeBaseConfig();
const template = [
    {
        path: baseUrlTenant+"/template" ,
        name: "template",
        component: () => import("@/view/layout/Layout"),
        children: [
            {
                name: "template",
                path: "/",
                component: () => import("@/view/pages/template_email/index")
            }
        ]
    },
]
export default template
