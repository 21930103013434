import ApiService from "@/core/services/api.service";

export const tenantApi = {
    list: (data = null) => {
        const searchParams = new URLSearchParams();
        let params = data?data: {};
        Object.keys(params).forEach(key => searchParams.append(key, params[key]));
        return ApiService.get("/tenant/list?"+searchParams.toString());
    },

    getDataCsv:(data = null) => {
        const searchParams = new URLSearchParams();
        let params = data?data: {};
        Object.keys(params).forEach(key => searchParams.append(key, params[key]));
        return  ApiService.get("/tenant/export-excel?"+searchParams.toString());
    },

    detail: (id = null) => {
        return ApiService.get("tenant/detail/"+id);
    },

    updateTenant: (data = null) => {
        return ApiService.put("tenant/update", data);
    },

    addTenant: (data = null) => {
        return ApiService.post("/tenant/add-tenant", data);
    },

    allRole: () => {
        return ApiService.get("/role/all-role");
    },
    all: () => {
        return ApiService.get("/tenant/all");
    },
    checkTenantExits: (tenantDir) => {
        return ApiService.post("/tenant/check-exits", {'tenant_dir':tenantDir});
    }

}
