import { ENV } from "@/core/config/env";
import routeTenant from "@/core/services/routeTenant.service";
const baseUrlTenant = routeTenant.routeBaseConfig();
const answer = [
    {
        path: baseUrlTenant + "/form-answer",
        name: "form-answer",
        redirect: `${baseUrlTenant}/form/list`,
        component: () => import("@/view/layout/Layout"),
        children: [
            {
                name: "form-answer-list",
                path: "list",
                component: () => import("@/view/pages/answer/List")
            }
        ]
    }
]

export default answer
