import { approvalBase } from "@/core/services/apis/approvalBase";
import { STATUS_CODE } from "@/core/config/constant";
import {SET_ERROR} from "@/core/services/store/auth.module";
import * as constants from "@/core/config/constant";

const { AUTHENTICATE, ERROR_SERVER, SUCCESS } = STATUS_CODE;

export const LIST_APPROVAL = "listApproval";
export const GET_LIST_APPROVAL = "getListApproval";
export const EXPORT_APPROVAL_FILE_CSV = "exportApprovalFileCsv";
export const GET_LIST_ROLE_NAME = "getListRoleName";
export const LIST_ROLE_NAME = "listRoleName";

const state = {
    listApproval: {},
    listRoleName: {},
};

const getters = {
    listApproval:state => state.listApproval,
    listRoleName:state => state.listRoleName,
};

const mutations = {
    [LIST_APPROVAL](state, data) {
        state.listApproval = data;
    },
    [LIST_ROLE_NAME](state, data) {
        state.listRoleName = data;
    },
};

const actions = {
    async [GET_LIST_APPROVAL](context,query){
        try {
            let response = await approvalBase.list(query);
            if (response.data.code == SUCCESS) {
                context.commit(LIST_APPROVAL, response.data.data);
            } else {
                throw response.data.message;
            }
        } catch (e) {
            if (e.response.data.code == ERROR_SERVER) {
                context.commit(SET_ERROR, e.response.data.message);
                throw e.response.data.message
            } else {
                throw e;
            }
        }
    },
    async [EXPORT_APPROVAL_FILE_CSV](context,query){
        try {
            let response = await approvalBase.getDataCsv(query);
            if (response.status == SUCCESS) {
                return response.data
            } else {
                throw response.data.message;
            }
        } catch (e) {
            if (e.response.data.code == ERROR_SERVER) {
                context.commit(SET_ERROR, e.response.data.message);
                throw e.response.data.message
            } else {
                throw e;
            }
        }
    },
    async [GET_LIST_ROLE_NAME](context,query){
        try {
            let response = await approvalBase.roleList(query);
            if (response.data.code == SUCCESS) {
                context.commit(LIST_ROLE_NAME, response.data.data);
            } else {
                throw response.data.message;
            }
        } catch (e) {
            if (e.response.data.code == ERROR_SERVER) {
                context.commit(SET_ERROR, e.response.data.message);
                throw e.response.data.message
            } else {
                throw e;
            }
        }
    },
};


export default {
    state,
    actions,
    mutations,
    getters
};
